body {
    margin: 0;
    padding: 0;
}

.scanner-container {
    position: relative;
}

.scanner-animation {
    position: absolute;
    top: 50px;
    z-index: 49;
    left: 75px;
    right: 75px;
    width: auto;
    height: 3px;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    opacity: 0.7;
    box-shadow: 0px 0px 8px 10px rgba(138, 127, 128, 0.49);
    animation-name: scan;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes scan {
    0% {
        box-shadow: 0px 0px 8px 10px rgba(138, 127, 128, 0.49);
        top: calc(0% + 60px);
    }

    50% {
        box-shadow: 0px 6px 8px 10px rgba(138, 127, 128, 0.49);
        top: calc(100% - 3px - 60px)
    }

    100% {
        box-shadow: 0px -6px 8px 10px rgba(138, 127, 128, 0.49);
        top: calc(0% + 60px);
    }
}
